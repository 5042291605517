<template>

  <div class="pbf">
  <template v-if="product_data?.id">

    <BrandHead 
      :id="product_data.id"
      :nm="product_data.name"
      :nmalt="product_data.name"
      :logo="product_data.item_brand.logo_s"
      :link="product_data.section"
      :rate="product_data.item_stat?.avg_rate"
      :slogan="product_data.slogan"
      :cnt_followers="product_data.item_stat?.cnt_followers"
      :isfollow="product_data?.addon?.follow"
      :type="type"
      :offical_user="product_data.offical_user"
      :bread="[
        {name: $t('category_' + product_data.category), link: '/' + product_data.link.split('/')[1]},
        {name: product_data.item_brand.name, link: product_data.item_brand.link},
        {name: product_data.name, link: product_data.link},
      ]"
      :brand_name="product_data.item_brand.name"
      :brand_link="product_data.item_brand.link"
      @follow="follow(1)"
      @unfollow="follow(0)"
      >
      <UiTabs 
        :tabs="getTabs" 
        :acttab="active_tab"
        />    
      </BrandHead>

    <div class="pb grid_width">  
      <NuxtPage 
        :data="product_data"
        :id="product_data.id"
        />
        
    </div>

    <BrandContextMenu 
      :is-follow="product_data?.addon?.follow" 
      @follow="follow(1)"
      @unfollow="follow(0)"
      />
      

  </template> 
  
  <template v-else>
    <SkBrandOpened />
  </template> 

  </div>
</template>

<script setup>

import { watchArray } from '@vueuse/core'
import { on } from 'events';

const route = useRoute();
const router = useRouter();
const {$api, $tagsUtil, $ga, $follow, $popup} = useNuxtApp()
var link = ref(route.params['id']);
var link2 = ref(route.params['id2']);
var active_tab = ref('info');
var type = ref('product');



const getTabs = computed(() => {  

  var items = [];

  items.push({
    id: 'info',
    name: 'Info',
    link: product_data.value?.link + '',          
    count: 0,
    icon: '',
  });

  if(product_data.value?.item_stat?.cnt_reports > 0){
    items.push({
      id: 'diaries',
      name: 'Diaries',
      link: product_data.value?.link + '/diaries',
      count: product_data.value?.item_stat?.cnt_reports,
      icon: '',
    });
  }

  if(product_data.value?.item_stat?.cnt_growers > 0){
    items.push({
      id: 'growers',
      name: 'Growers',
      link: product_data.value?.link + '/growers',
      count: product_data.value?.item_stat?.cnt_growers,
      icon: '',
    });
  }

  if(product_data.value?.item_stat?.cnt_reviews > 0){
    items.push({
      id: 'reviews',
      name: 'Reviews',
      link: product_data.value?.link + '/reviews',
      count: product_data.value?.item_stat?.cnt_reviews,
      icon: '',
    });
  }


  return items;
   
})  
 
const follow = function(state) {  
  $follow.toggle('product', product_data.value.id, state, {
    type: 'product',
    id: product_data.value.id,
    avatar: product_data.value.avatar_small, 
    name: product_data.value.name, 
    link: product_data.value.link, 
  })
  .then((response) => {    
    product_data.value.addon.follow = state;
    if(product_data.value.item_stat)
      product_data.value.item_stat.cnt_followers += state ? 1 : -1;
  })
  .catch((error) => {
    $popup.error('Error')
  });
}
 

const loadData = async function(product, brand) {     
  const response = await $api.getUrl('/ref/products/' + product, {
    brandsection: brand, 
  })  
  return response;  
}


const { pending: is_loading,  data: product_data } = await useLazyAsyncData('product_data_' + link2.value, async () => await loadData(link2.value, link.value))


// watch( () => product_data, () => {
//   useHead(useNuxtApp().$head.getProduct(product_data.value));
// }, {immediate: true, deep: true});
 
// watch( () => router.currentRoute, () => {
//   useHead(useNuxtApp().$head.getProduct(product_data.value));
// }, {immediate: true, deep: true});
 



watchArray([route], async ([new_route], added, removed) => {
  useHead(useNuxtApp().$head.getProduct(product_data.value));
}, {deep: true})

useHead(useNuxtApp().$head.getProduct(product_data.value));

onBeforeUnmount(() => {
  product_data.value = null;
})

  
</script>

<style scoped>


.add_filter_box{
  display: flex;
  margin-left: auto;
}
.flow_boxes {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

</style>
